<template>
  <div class="video-page">
    <template v-if="!loading">
      <div class="top-nav">
        <div class="content-wrap">
          当前位置：<span @click="toHome('')">首页</span><span @click="toBack()">{{ courseInfo.category && courseInfo.category.name ? ' > ' + courseInfo.category.name : '' }}</span> > <span @click="toBack()">{{ courseInfo.name }}</span>
        </div>
      </div>
      <div class="video-content">
        <div class="video-play-wrap" id="videoNode" :style="{'width' : isOpen ? 'calc( 100% - 360px )' : '100%', 'margin-left' : isOpen ? '360px' : '0'}">
          <video ref="videoNode" webkit-playsinline playsinline x5-playsinline width="100%" height="100%" class="video-js vjs-default-skin vjs-duration" data-setup='{}'>
            <source :src="videoUrl" type="video/mp4">
          </video>
          <div v-if="bLoading" class="loading-wrap" v-loading="bLoading" :loading.sync="bLoading" element-loading-text="加载中..." element-loading-background="#282828"></div>
        </div>
        <div v-if="!isOpen" class="open-btn" @click="isOpen = true">
          <img src="../assets/img/video_open_icon.png" />
        </div>
        <div v-show="isOpen" class="left-catalog">
          <div class="catalog-left">
            <div class="catalog-left-content">
              <img src="../assets/img/video_catalog_icon.png" />
              <span>目录</span>
            </div>
          </div>
          <div class="list-wrap">
            <el-tree
              ref="tree"
              :data="catalogData"
              highlight-current
              :props="defaultProps"
              node-key="id"
              :current-node-key="curCatalogID"
              :default-expanded-keys="[curCatalogID]"
              :default-checked-keys="[curCatalogID]"
              @node-click="handleNodeClick"
            >
              <div class="catalog-wrap" slot-scope="{ node, data }">
                <div class="catalog-wrap-item" v-if="data.children && data.children.length > 0">
                  <span :id="data.id" class="catalog-name">{{ node.label }}</span>
                </div>
                <div class="catalog-wrap-item catalog-wrap-last" v-else>
                  <template v-if="data.video">
                    <div class="top">
                      <div class="top-image" />
                      <span :id="data.id" class="catalog-name">{{ node.label }}</span>
                      <template v-if="data.video && data.video.is_over === 1">
                        <img class="video-view-process" src="../assets/img/video_over.png" />
                      </template>
                      <template v-else>
                        <img class="video-view-process" v-if="data.video.video_times > 0 && data.video.video_times < data.video.video_duration" src="../assets/img/video_playing.png" />
                        <img class="video-view-process" v-else-if="data.video.video_times >= data.video.video_duration" src="../assets/img/video_over.png" />
                        <img class="video-view-process" v-else src="../assets/img/video_no_view.png" />
                      </template>
                    </div>
                    <div class="bottom">
                      <span>录播</span>
                      <div>{{ data.video.video_times > data.video.video_duration ? formatTime(data.video.video_duration) : data.id === curCatalogID ? formatTime(player.currentTime()) : formatTime(data.video.video_times)}} / {{ formatTime(data.video.video_duration) }}</div>
                    </div>
                  </template>
                </div>
              </div>
            </el-tree>
          </div>
          <div class="close-btn" @click="isOpen = false">
            <img src="../assets/img/video_close_icon.png" />
          </div>
        </div>
      </div>
    </template>
    <application-dialog :application-dialog="applicationDialog" @hideDialog="hideApplicationDialog" />
    <download-dialog :download-dialog="downloadDialog" @hideDialog="hideDownloadDialog" />
    <loading :is-load="loading" />
    <div v-if="showFluent" class="video-fluent">
      <span :class="{'selected' : fluent === '标清'}" @click="checkFluent('标清')">标清</span>
      <span :class="{'selected' : fluent === '高清'}" @click="checkFluent('高清')">高清</span>
    </div>
  </div>
</template>

<script>
import videojs from 'video.js';
import videozhCN from 'video.js/dist/lang/zh-CN.json';
import 'video.js/dist/video-js.css';

import 'videojs-markers';
import 'videojs-markers/dist/videojs.markers.css';

import applicationDialog from '@/views/modules/application-dialog';
import downloadDialog from '@/views/modules/download-dialog';
import * as courseService from '@/service/course-service';
import { Message } from 'element-ui';
export default {
  name: 'videoDetail',
  components: {
    applicationDialog,
    downloadDialog
  },
  data() {
    return {
      bLoading: true,
      loading: false,
      isOpen: true,
      applicationDialog: false,
      downloadDialog: false,
      catalogData: [],
      defaultProps: {
        children: 'children',
        label: 'name'
      },
      playbackRates: [0.75, 1.0, 1.25, 1.5, 2.0], // 视频播放倍速
      courseInfo: {}, // 课程详情
      curCatalogID: '', // 当前选中视频章节id
      curCatalogInfo: {}, // 当前选中视频
      videoUrl: '', // 当前视频url
      isTry: false, // 是否试看
      nextCatalog: {}, // 下一课时
      showFluent: false,
      fluent: '标清',
      player: null,
      saveLogTimeInterval: null
    };
  },
  created() {
    this.getCourseInfo();
    this.isTry = !!+this.$route.query.isTry;
  },
  mounted() {
    this.bLoading = true;
    document.addEventListener('keydown', this.keydown, true);
  },
  methods: {
    keydown(event) {
      const time = 10;
      event.stopPropagation();
      if (this.player) {
        if (event.keyCode === 39) {
          this.player.currentTime(this.player.currentTime() + time);
        }
        // 后退
        if (event.keyCode === 37) {
          this.player.currentTime(this.player.currentTime() - time);
        }
        if (event.keyCode === 32) {
          if (this.player.paused()) {
            this.player.play();
          }
          else {
            this.player.pause();
          }
        }
        if (event.keyCode === 38) {
          this.player.volume(this.player.volume() + 0.1);
        }
        if (event.keyCode === 40) {
          this.player.volume(this.player.volume() - 0.1);
        }
      }
    },
    /**
     * 设置播放器
     * */
    setVideo() {
      const time = 10;
      const options = {
        autoplay: false, // 自动播放
        muted: false,
        language: 'zh-CN',
        controls: true, // 控制条
        preload: 'auto', // 自动加载
        errorDisplay: true, // 错误展示
        playbackRates: this.playbackRates,
        notSupportedMessage: '此视频暂无法播放，请稍后再试',
        sources: [
          {
            src: this.videoUrl
          }
        ],
        controlBar: {
          volumePanel: {
            inline: false
          },
          playToggle: true
        },
        userActions: {
          hotkeys: function(event) {
            // 38增加音量
            // 40 降低音量
            // 32 暂停
            // 快进
            if (event.which === 39) {
              this.currentTime(this.currentTime() + time);
            }
            // 后退
            if (event.which === 37) {
              this.currentTime(this.currentTime() - time);
            }
            if (event.which === 32) {
              if (this.paused()) {
                this.play();
              }
              else {
                this.pause();
              }
            }
            if (event.which === 38) {
              this.volume(this.volume() + 0.1);
            }
            if (event.which === 40) {
              this.volume(this.volume() - 0.1);
            }
          }
        }
      };

      this.player = videojs(this.$refs.videoNode, options);
      this.setMarks();
      this.player.on('loadedmetadata', () => {
        this.bLoading = false;
        this.player.play();
        // 设置上次播放时间
        if (this.curCatalogInfo.video.video_duration - this.curCatalogInfo.video.video_times > 1) {
          this.player.currentTime(this.curCatalogInfo.video.video_times);
        }
      });
      this.player.on('timeupdate', () => {
        if (!this.saveLogTimeInterval) {
          this.saveLogTimeInterval = setInterval(() => {
            this.setUserLiveLog();
          }, 5000);
        }
      });
      this.player.on('pause', () => {
        clearInterval(this.saveLogTimeInterval);
        this.saveLogTimeInterval = null;
      });
      // 使用事件监听
      this.player.on('ended', async() => {
        this.getAfterNode(this.catalogData, this.$refs.tree.getNode(this.curCatalogID).data);
        await this.setUserLiveLog(1);
        this.curCatalogID = this.nextCatalog.id;
        if (this.nextCatalog.video) {
          this.videoUrl = this.nextCatalog.video.video_url;
          this.$refs.tree.setCurrentKey(this.curCatalogID);
          this.curCatalogInfo = this.nextCatalog;
          this.player.src({ src: this.videoUrl });
          this.player.load();
        }
      });
      this.player.getChild('ControlBar').addChild('button', {
        controlText: '下一课时',
        className: 'vjs-visible-text',
        clickHandler: async() => {
          this.showFluent = false;
          if (!this.isTry) {
            await this.setUserLiveLog();
            this.curCatalogID = this.nextCatalog.id;
            if (this.nextCatalog.video) {
              this.videoUrl = this.nextCatalog.video.video_url;
              this.$refs.tree.setCurrentKey(this.curCatalogID);
              this.curCatalogInfo = this.nextCatalog;
              this.player.src({ src: this.videoUrl });
              this.player.load();
            }
            this.getAfterNode(this.catalogData, this.$refs.tree.getNode(this.curCatalogID).data);
          }
          else {
            Message({
              message: '此视频为试看',
              type: 'warning',
              duration: 1500,
              customClass: 'element-error-message-zindex'
            });
          }
        }
      });
      this.player.getChild('ControlBar').addChild('button', {
        controlText: '标清',
        className: 'vjs-visible-text',
        clickHandler: () => {
          this.showFluent = !this.showFluent;
        }
      });
      videojs.addLanguage('zh-CN', videozhCN);
    },
    /**
     * 设置卡点
     * */
    setMarks() {
      if (this.curCatalogInfo.point && this.curCatalogInfo.point.length > 0) {
        this.curCatalogInfo.point.map(item => {
          item.time = item.starttime;
        });
        this.player.markers({
          markerStyle: {
            // 标记点样式
            width: '0.3em',
            height: '0.3em',
            'background-color': '#000000',
            position: 'absolute'
          },
          markerTip: {
            display: true, // 是否显示
            /*
              用于动态构建标记提示文本的回调函数。
              只需返回一个字符串，参数标记是传递给插件的标记对象
             */
            text: function(marker) {
              return marker.name;
            }
          },
          markers: this.curCatalogInfo.point
        });
      }
    },
    /**
     * 查询课程详情
     * */
    getCourseInfo() {
      const data = {
        id: this.$route.query.id
      };
      this.loading = true;
      courseService.getCourseInfo(data).then(res => {
        this.loading = false;
        if (res.code === 1) {
          this.courseInfo = res.data;
          // course_user1_count > 0 已经报名或者已经购买 == 0 未报名或未购买
          if (this.courseInfo.course_user1_count > 0) {
            this.isTry = false;
          }
          this.catalogData = res.data.outline;
          this.$nextTick(() => {
            this.bLoading = true;
            this.curCatalogID = Number(this.$route.query.catalogID);
            this.$refs.tree.setCurrentKey(this.curCatalogID);
            this.curCatalogInfo = this.$refs.tree.getNode(this.curCatalogID).data;
            this.videoUrl = this.curCatalogInfo.video.video_url;
            this.setVideo();
            if (!this.isTry) {
              this.getAfterNode(this.catalogData, this.curCatalogInfo);
            }
          });
        }
      });
    },
    async handleNodeClick(data) {
      if (!data.children || data.children.length === 0) {
        this.getAfterNode(this.catalogData, data);
        // 保存用户观看记录
        await this.setUserLiveLog();
        // 未购买
        if (this.courseInfo.course_user1_count === 0 && data.is_try !== 1) {
          // 立即购买
          if (this.courseInfo.is_free === 0) {
            this.downloadDialog = true;
          }
          // 立即报名
          else {
            this.applicationDialog = true;
          }
          return;
        }
        if (data.video) {
          this.bLoading = true;
          this.curCatalogID = data.id;
          this.videoUrl = data.video.video_url;
          this.curCatalogInfo = data;
          if (!this.isTry) {
            this.player.src({ src: this.videoUrl });
            this.player.load();
          }
        }
      }
    },
    /**
     * 选择清晰度
     * */
    checkFluent(title) {
      this.fluent = title;
      this.showFluent = false;
      const buttons = document.querySelectorAll('.vjs-visible-text');
      buttons.forEach(item => {
        if (item.innerText === '标清' || item.innerText === '高清') {
          item.innerHTML = title;
        }
      });
    },
    getAfterNode(data, node) { // 获取同级后一个节点，data整棵树节点，node当前节点
      let obj = {};
      for (let i = 0; i < data.length; i++) {
        if (data[i].id === node.id) {
          if (i < (data.length - 1)) {
            obj = data[i + 1];
            if (obj.children && obj.children.length > 0) {
              if (obj.children[0].children && obj.children[0].children.length > 0) {
                if (obj.children[0].children[0].children && obj.children[0].children[0].children.length > 0) {
                  this.nextCatalog = obj.children[0].children[0].children[0];
                }
                else {
                  this.nextCatalog = obj.children[0].children[0];
                }
              }
              else {
                this.nextCatalog = obj.children[0];
              }
            }
            else {
              this.nextCatalog = obj;
            }
            return;
          }
          else { // 没有后面一个节点
            if (this.$refs.tree.getNode(data[i].parent_id)) {
              this.getAfterNode(this.catalogData, this.$refs.tree.getNode(data[i].parent_id).data);
            }
          }
        }
        else if (data[i].children) { // 有下级，递归查询
          this.getAfterNode(data[i].children, node);
        }
      }
    },
    /**
     * 视频播放结束
     * */
    playEnd() {
      this.getAfterNode(this.catalogData, this.$refs.tree.getNode(this.curCatalogID).data);
      this.setUserLiveLog(1);
      this.curCatalogID = this.nextCatalog.id;
      this.videoUrl = this.nextCatalog.video.video_url;
      this.$refs.tree.setCurrentKey(this.curCatalogID);
      this.curCatalogInfo = this.nextCatalog;
      setTimeout(() => {
        this.$refs.livePlayer.setCurrentTime(this.curCatalogInfo.video.video_times);
      }, 1000);
    },
    /**
     * 保存用户观看记录
     * */
    setUserLiveLog(isOver) {
      if (!this.isTry) {
        const curTime = Math.floor(this.player.currentTime());
        this.reWriteVideoTime(this.catalogData, curTime);
        const params = {
          is_over: isOver || curTime >= this.curCatalogInfo.video.video_duration ? 1 : 0,
          speed: `${(curTime / this.curCatalogInfo.video.video_duration).toFixed(2) * 100}%`,
          chapter_id: this.curCatalogInfo.video.id,
          video_times: curTime
        };
        courseService.addUserLiveLog(params).then(res => {
          if (res.code === 1) {
            // this.reWriteVideoTime(this.catalogData, curTime);
          }
        });
      }
    },
    reWriteVideoTime(data, time) {
      for (let i = 0; i < data.length; i++) {
        if (data[i].id === this.curCatalogID) {
          data[i].video.video_times = time;
        }
        else if (data[i].children) {
          this.reWriteVideoTime(data[i].children, time);
        }
      }
    },
    /**
     * 视频时长转换
     * */
    formatTime(duration) {
      const hour = parseInt(duration / 3600);
      const minute = parseInt((duration % 3600) / 60) > 9 ? parseInt((duration % 3600) / 60) : '0' + parseInt((duration % 3600) / 60);
      const seconds = parseInt(duration % 60) > 9 ? parseInt(duration % 60) : '0' + parseInt(duration % 60);
      const timeStr = `${hour}:${minute}:${seconds}`;
      return timeStr;
    },
    /**
     * 隐藏报名弹窗
     * */
    hideApplicationDialog() {
      this.applicationDialog = false;
    },
    /**
     * 隐藏app弹窗
     * */
    hideDownloadDialog() {
      this.downloadDialog = false;
    },
    /**
     * 首页
     * */
    toHome() {
      this.$router.push({
        path: '/'
      });
    },
    /**
     * 返回课程详情
     * */
    toBack() {
      this.$router.go(-1);
    }
  },
  async beforeDestroy() {
    await this.setUserLiveLog();
    clearInterval(this.saveLogTimeInterval);
    this.saveLogTimeInterval = null;
    if (this.player) {
      this.player.dispose();
    }
    document.removeEventListener('keydown');
  },
  async destroyed() {
    await this.setUserLiveLog();
    clearInterval(this.saveLogTimeInterval);
    this.saveLogTimeInterval = null;
    if (this.player) {
      this.player.dispose();
    }
    document.removeEventListener('keydown');
  }
};
</script>

<style lang="scss">
.video-page {
  width: 100%;
  height: 100vh;
  background: #2F2F2F;
  display: flex;
  flex-direction: column;
  .top-nav {
    background: #282828;
    width: 100%;
    .content-wrap {
      display: flex;
      align-items: center;
      height: 40px;
      font-size: 14px;
      color: #E0E0E0;
      span {
        cursor: pointer;
      }
    }
  }
  .video-content {
    display: flex;
    flex: 1;
    min-width: 1200px;
    position: relative;
    .left-catalog {
      display: flex;
      width: 360px;
      height: 100%;
      background: #282828;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 100;
      .catalog-left {
        .catalog-left-content {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 60px;
          height: 80px;
          background: #2F2F2F;
          img {
            width: 16px;
            height: 16px;
            margin-bottom: 8px;
          }
          span {
            font-size: 14px;
            color: #FFFFFF;
          }
        }
      }
      .list-wrap {
        flex: 1;
        background: #2F2F2F;
        padding-top: 20px;
        height: 100%;
        overflow-y: auto;
        .el-tree {
          >.el-tree-node {
            padding: 0 21px 0 12px;
            background: #2F2F2F;
          }
          .el-tree-node {
            white-space: unset;
          }
          .catalog-wrap {
            width: 100%;
          }
          .catalog-wrap-item {
            padding-right: 15px;
            .catalog-name {
              font-size: 14px;
              font-weight: 400;
              color: #FFFFFF;
              line-height: 20px;
            }
          }
          .catalog-wrap-last {
            .top {
              display: flex;
              .top-image {
                width: 7px;
                height: 11px;
                margin-right: 8px;
                background: url("../assets/img/video_pause_icon.png") no-repeat 100% 100%;
                margin-top: 4px;
              }
              .catalog-name {
                font-size: 14px;
                font-weight: 400;
                color: #C3C5D9;
                line-height: 20px;
                flex: 1;
              }
              .video-view-process {
                width: 16px;
                height: 16px;
                margin-left: 10px;
                margin-top: 2px;
              }
            }
            .bottom {
              display: flex;
              align-items: center;
              font-size: 12px;
              font-weight: 400;
              color: #77777D;
              padding-left: 15px;
              margin-top: 10px;
              span {
                margin-right: 28px;
              }
            }
          }
          .el-tree-node__children {
            .catalog-name {
              font-size: 14px;
            }
          }
          .el-tree-node__content {
            position: relative;
            width: 100%;
            height: auto;
            line-height: 1;
            padding: 12px 0;
          }
          .el-tree-node__expand-icon {
            position: absolute;
            right: 0;
            top: calc( 50% - 8px );
          }
          .el-tree-node__expand-icon.is-leaf {
            display: none;
          }
          .el-tree-node__expand-icon:before {
            content: '';
          }
          .el-tree-node__expand-icon {
            background: url("../assets/img/video_down_icon.png") no-repeat 100% 100%;
            background-size: 13px 7px;
          }
          .el-tree-node__expand-icon.expanded {
            transform: rotate(0);
            background: url("../assets/img/video_up_icon.png") no-repeat 100% 100%;
          }
          .el-tree-node__content:hover {
            background-color: transparent;
          }
          .el-tree-node:focus > .el-tree-node__content {
            background-color: transparent;
          }
        }
        .el-tree--highlight-current .el-tree-node.is-current>.el-tree-node__content {
          background-color: transparent;
          .catalog-wrap-last {
            .top {
              .top-image {
                background: url("../assets/img/video_pause_selected.png") no-repeat 100% 100%;
              }
            }
            .catalog-name {
              color: #2669F4 !important;
            }
          }
        }
      }
      .close-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        width: 30px;
        height: 60px;
        top: calc( 50% - 30px );
        right: -30px;
        background: #2F2F2F;
        border-radius: 0px 10px 10px 0px;
        cursor: pointer;
        img {
          width: 11px;
          height: 20px;
        }
      }
    }
    .video-play-wrap {
      background: #FFFFFF;
      width: 100%;
      height: 100%;
      outline: none;
      :focus-visible {
        outline: none;
      }
      .video-js {
        width: 100%;
        height: 100%;
      }
      .video-js .vjs-load-progress {
        background-color: #6F859F;
      }
      .vjs-visible-text {
        cursor: pointer;
      }
      .video-js .vjs-control-bar {
        background-color: #282828;
        height: 4em;
      }
      .vjs-button > .vjs-icon-placeholder:before {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .vjs-has-started.vjs-user-inactive.vjs-playing .vjs-control-bar {
        opacity: 1;
      }
      .video-js .vjs-slider {
        background-color: #6F859F;
      }
      .video-js .vjs-play-progress {
        background-color: #00A4FF;
      }
      .vjs-picture-in-picture-control {
        display: none;
      }
      .vjs-text-track-display {
        display: none;
      }
      .vjs-playback-rate-value {
        line-height: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        color: #FFFFFF;
      }
      .vjs-menu {
        font-size: 12px;
        color: #FFFFFF;
        .vjs-menu-content {
          background: rgba(0, 0, 0, 0.6);
          border-radius: 4px;
          max-height: unset;
          bottom: 1em;
        }
        .vjs-selected {
          background-color: transparent;
          color: #3778FF;
        }
      }
      .vjs-menu li {
        line-height: 1.8em;
      }
      .vjs-remaining-time {
        display: none;
      }
      .vjs-current-time.vjs-time-control.vjs-control, .vjs-time-control.vjs-time-divider, .vjs-duration.vjs-time-control.vjs-control {
        display: block;
        padding: 0;
        min-width: unset;
        line-height: 4em;
      }
      .vjs-big-play-button {
        display: none !important;
      }
      .video-js .vjs-volume-panel .vjs-volume-control {
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1000;
        background: rgba(0, 0, 0, 0.6);
        width: 40px;
        height: 150px;
        border-radius: 4px;
        bottom: 146px;
      }
      .video-page .video-content .video-play-wrap .player-wrapper .video-wrapper .video-js .vjs-slider {
        background-color: #70717B;
      }
      .vjs-volume-bar.vjs-slider-vertical {
        height: 120px;
      }
      .video-js .vjs-volume-level {
        background-color: #3778FF;
      }
      .player-wrapper {
        height: 100%;
        .video-wrapper {
          height: 100%;
          padding-bottom: 0 !important;
        }
      }
      .loading-wrap {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: 100%;
        z-index: 100;
      }
    }
    .open-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      width: 30px;
      height: 60px;
      top: calc( 50% - 30px );
      left: 0;
      background: rgba(47, 47, 47, 0.3);
      border-radius: 0px 10px 10px 0px;
      cursor: pointer;
      img {
        width: 11px;
        height: 20px;
      }
    }
  }
  .video-fluent {
    position: absolute;
    right: 0;
    bottom: 1.8em;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;
    width: 3em;
    background: rgba(0, 0, 0, 0.6);
    border-radius: 4px;
    span {
      display: flex;
      justify-content: center;
      width: 100%;
      flex: 1;
      text-align: center;
      font-size: 12px;
      color: #FFFFFF;
      cursor: pointer;
      line-height: 30px;
      height: 30px;
    }
    span:hover {
      background-color: rgba(255, 255, 255, 0.3);
    }
    .selected {
      background-color: transparent;
      color: #3778FF;
    }
  }
}
</style>
